<template>
  <v-card>
    <v-card-title>Configuration</v-card-title>
    <v-card-text>
      <v-textarea v-model="configString" rows="12" />
      <v-row>
        <v-col cols="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :hint="dateMessage"
                persistent-hint
                label="Publication Date"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              :color="$colors.blue"
              :min="today"
              :allowed-dates="allowedDates"
              @input="menu2 = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="phrase" readonly label="Phrase" />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="category" label="Category" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="author.name"
            label="Author name"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="author.link"
            label="Author link"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="editor" label="Editor"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="destination"
            :items="db_tables"
            item-text="name"
            item-value="table"
            label="Destination"
          />
        </v-col>
        <v-col cols="6">
          <v-select v-model="action" :items="db_actions" label="Action" />
        </v-col>
      </v-row>
      <v-btn
        block
        :color="$colors.green"
        :disabled="!enable"
        class="white--text"
        @click="upload"
      >
        Upload Configuration
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["mode"],
  data: () => ({
    action: "Insert",
    author: {
      link: "",
      name: "",
    },
    availableDates: [],
    category: null,
    date: null,
    db_actions: ["Insert", "Replace", "Delete"],
    db_tables: [
      {
        name: "Arkadium",
        table: "puzzles_arkadium",
      },
      {
        name: "blockables.app",
        table: "puzzles",
      },
    ],
    destination: "puzzles",
    editor: "",
    menu2: false,
    protectOverwrite: [],
  }),
  computed: {
    configuration() {
      return {
        author: this.author,
        category: this.category,
        clues: this.$store.state.CREATOR.clues,
        date: this.date,
        direction: this.$store.state.CREATOR.direction,
        gridColors: this.$store.state.CREATOR.gridColors,
        grid: this.$store.state.CREATOR.grid,
        numberPlacements: this.$store.state.CREATOR.numberPlacements,
        ordinal: this.ordinal,
        phrase: this.phrase,
      };
    },
    configString() {
      return JSON.stringify(this.configuration);
    },
    dateMessage() {
      let r = null;
      try {
        if (this.mode == "create" && this.pastPuzzle) {
          r = "Puzzle must be scheduled for the future.";
        } else {
          r = "Will be displayed as Puzzle #" + this.ordinal;
        }
      } catch {
        r = null;
      }
      return r;
    },
    enable() {
      return this.today != this.date && this.phrase && this.category;
    },
    ordinal() {
      let o = null;
      try {
        let then = process.env.VUE_APP_DEBUT_DATE.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = this.date.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((now - then) / (1000 * 60 * 60 * 24)) + 1;
      } catch {
        o = null;
      }
      return o;
    },
    pastPuzzle() {
      let o = null;
      try {
        let future = this.today.split("-");
        future = new Date(future[0], future[1] - 1, future[2]);
        let now = this.date.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        return future > now || this.today == this.date;
      } catch {
        o = null;
      }
      return o;
    },
    phrase() {
      return this.$store.state.CREATOR.phrase;
    },
    today() {
      if (this.mode == "editor") return "1999-12-31";
      let d = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );

      let yyyy = d.getFullYear();
      let mm = d.getMonth() + 1 > 12 ? 1 : d.getMonth() + 1;
      mm = mm > 9 ? mm : "0" + mm;
      let dd = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
      return `${yyyy}-${mm}-${dd}`;
    },
  },
  watch: {
    action: {
      handler() {
        if (this.action != "Insert") {
          this.availableDates = this.next365Days([]);
        } else {
          this.availableDates = this.next365Days(this.protectOverwrite);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.mode == "editor") {
      this.date = this.$route.params.d;
      this.category = this.$store.state.PUZZLE.metadata.category;
      return;
    }
    let d = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );

    let yyyy = d.getFullYear();
    let mm = d.getMonth() + 1 > 12 ? 1 : d.getMonth() + 1;
    mm = mm > 9 ? mm : "0" + mm;
    let dd = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
    this.date = `${yyyy}-${mm}-${dd}`;

    let { data, error } = await this.$db
      .from("puzzles")
      .select("date")
      .gte("date", this.date);
    this.protectOverwrite = data.map(d => d.date);
    if (!error) {
      this.availableDates = this.next365Days(this.protectOverwrite);
    }
  },
  methods: {
    allowedDates(val) {
      return this.availableDates.includes(val);
    },
    next365Days(excludeDates) {
      const today = new Date();
      const next365Days = [];

      for (let i = 0; i < 365; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);

        const year = nextDay.getFullYear();
        const month = (nextDay.getMonth() + 1).toString().padStart(2, "0");
        const day = nextDay.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;

        if (!excludeDates.includes(formattedDate)) {
          next365Days.push(formattedDate);
        }
      }

      return next365Days;
    },
    async upload() {
      if (this.action == "Insert") {
        const resp = await this.$db
          .from(this.destination)
          .select()
          .eq("date", this.date);

        if (resp.data.length) {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: true,
            text: "That puzzle already exists. Please choose a different date.",
            type: "bad",
          });
          return;
        }
        this.insertPuzzle();
      }
      if (this.action == "Replace") {
        this.updatePuzzle();
      }
      if (this.action == "Delete") {
        this.deletePuzzle();
      }
    },
    async deletePuzzle() {
      try {
        const { error } = await this.$db
          .from(this.destination)
          .delete()
          .eq("date", this.date);
        console.log(error);
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: `Puzzle ${this.ordinal} was successfully deleted!`,
          type: "success",
        });
        setTimeout(() => {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: false,
            text: "",
            type: "success",
          });
        }, 3000);
      } catch {
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: "Could not delete puzzle. Blockables was unable to contact the server. Please check your connection or try again later.",
          type: "bad",
        });
      }
    },
    async insertPuzzle() {
      try {
        const { error } = await this.$db
          .from(this.destination)
          .insert(this.configuration);
        console.log(error);
        await this.$db
          .from("phrases")
          .delete()
          .eq("phrase", this.configuration.phrase);
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: `Puzzle ${this.ordinal} was successfully uploaded and scheduled for ${this.date}!`,
          type: "success",
        });
        setTimeout(() => {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: false,
            text: "",
            type: "success",
          });
        }, 3000);
      } catch {
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: "Could not upload puzzle. Blockables was unable to contact the server. Please check your connection or try again later.",
          type: "bad",
        });
      }
    },
    async updatePuzzle() {
      try {
        const { data, error } = await this.$db
          .from(this.destination)
          .update(this.configuration)
          .eq("date", this.date)
          .select();
        console.log({ data, error });
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: `Puzzle ${this.ordinal} was successfully updated!`,
          type: "success",
        });
        setTimeout(() => {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: false,
            text: "",
            type: "success",
          });
        }, 3000);
      } catch {
        this.$store.commit("USER/SNACKBAR_SET", {
          active: true,
          text: "Could not update puzzle. Blockables was unable to contact the server. Please check your connection or try again later.",
          type: "bad",
        });
      }
    },
  },
};
</script>

<style></style>
